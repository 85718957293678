import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';

/* import enTranslation from './locales/en.json';
import deTranslation from './locales/de.json';
 */
i18n
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        
        /* resources: {
            en: { translation: enTranslation },
            de: { translation: deTranslation },
        }, */
        
        lng: 'de_de',
        fallbackLng: 'en_gb',
        whitelist: ['en_gb', 'de_de', 'es_es', 'fr_fr'],

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    });

export default i18n;