// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ra_button {
    background-color: #333;
    color: white;
    border: none;
    padding: 8px 20px;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 0.5rem;
}

.ra_button:hover {
    background-color: #ff6200;
}`, "",{"version":3,"sources":["webpack://./src/components/FontSizeChanger.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.ra_button {\n    background-color: #333;\n    color: white;\n    border: none;\n    padding: 8px 20px;\n    cursor: pointer;\n    font-size: 1rem;\n    margin-right: 0.5rem;\n}\n\n.ra_button:hover {\n    background-color: #ff6200;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
