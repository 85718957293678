/******************************************************************************
 *                                                                            *
 *  DeleteAccountPage.js                                                      *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React from "react";
import './DeleteAccountPage.css'
import Context from "../context";
import { withTranslation } from 'react-i18next';

const DeleteAccountPage = (props) => {
    const { t } = props;
    const errorMessageElementEmail = document.getElementById('error-message-email');
    
    // Clear previous error message
    errorMessageElementEmail.textContent = '';
    errorMessageElementEmail.style.display = 'none';

    const sendDeletionEMail = () => {
        const { t } = props;

        const email = document.getElementById('email').value;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            errorMessageElementEmail.textContent = t("INVALID_MAILFORMAT"); // Assuming t is a translation function
            errorMessageElementEmail.style.display = 'block'; // Show the error message
            return;
        }

        const activationKey = Context.generateToken( 16);

        var o = {
            email        : email,
            activationkey: activationKey,
            mailSubject  : t("ACCOUNT_DELETION"),
            mailBody     : t("MAIL_BODY_DELETION", { "LINK": Context.frontendURL+ "deleteAccount?activationKey="+ activationKey })
        };
        Context.api.post('preparePasswordReset', o ).then( () => {
            alert( t("MAIL_HAS_BEEN_SENT") );
            props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div className="stddlg-outer">
            <div className="stddlg">
                <div dangerouslySetInnerHTML={{__html: t("MESSAGE", { "USERNAME": Context.username })}} />
                <br/>
                <br/>
                <form >
                    {t("ENTER_USED_EMAIL")}:
                    <br/>
                    <br/>
                    <p>
                        <input type="text" className="loginfeld" placeholder={t("MAIL_ADDRESS")} name="email" id="email" required/>
                    </p>
                    <div>
                            <span id="error-message-email" className="error" style={{display:'none', color:'red'}}></span>
                    </div>
                    <br/>
                    <p>
                        <input type="button" className="reset-button" onClick={ () => sendDeletionEMail() } value={t("SEND_DELETION_MAIL")}/>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default withTranslation(['deleteAccountPage', 'common'])(DeleteAccountPage);
