/******************************************************************************
 *                                                                            *
 *  ProfilePage.js                                                            *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React, {useEffect} from "react";
import './ProfilePage.css'
import Context from "../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

const ProfilePage = (props) => {
    const { t } = props;

    const [data, setData] = React.useState({});

    useEffect( () => { 
        Context.api.get('retrieveUser/'+ Context.username, {
            auth: {
                username: Context.username,
                password: Context.password
            }}).then( res => {
            if ( res.data.shownotifications === '' ) {
                res.data.shownotifications = "D";
            }
            res.data.currentUsername = Context.username;
            setData( res.data );
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }, []);

    const changeLanguage = (language) => {
        setData( Object.assign( {}, data, {language : language})  );
        Context.language = language;
        Cookies.set( 'language', language );
        i18n.changeLanguage( Context.language );
    };


    const doUpdateUser = () => {
        if ( data.username === "" ) {
            alert( t("ENTER_USERNAME"));
            return;
        }

        const email = data.email;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            alert( t("MAILFORMAT_INVALID") )
            return;
        }

        Context.api.post('updateUser', data, {
            auth: {
                username: Context.username,
                password: Context.password
            }} ).then( () => {
            alert( t("PROFILE_WAS_SAVED"))
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div id="divRegisterOuter" className="stddlg-outer">
            <div id="divRegister" className="stddlg">
                <form >
                    <p>
                        {t("USERNAME")}: <input class="profilfeld" type="text" value={data.username} onChange={e => setData( Object.assign( {}, data, {username : e.target.value}))} required/>
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("MAIL")}: <input class="profilfeld" type="text" value={data.email} onChange={e => setData( Object.assign( {}, data, {email : e.target.value}))} required/>
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("LASTNAME")}: <input class="profilfeld" type="text" value={data.lastname} onChange={e => setData( Object.assign( {}, data, {lastname : e.target.value}))} />
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("FIRSTNAME")}: <input class="profilfeld" type="text" value={data.firstname} onChange={e => setData( Object.assign( {}, data, {firstname : e.target.value}))} />
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("CITYCODE")}: <input class="profilfeld" type="text" value={data.postcode} onChange={e => setData( Object.assign( {}, data, {postcode : e.target.value}))} />
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("CITY")}: <input class="profilfeld" type="text" value={data.city} onChange={e => setData( Object.assign( {}, data, {city : e.target.value}))} />
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("ADDRESS")}: <input class="profilfeld" type="text" value={data.address} onChange={e => setData( Object.assign( {}, data, {address : e.target.value}))} />
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("TELEPHONE")}: <input class="profilfeld" type="text" value={data.telephone} onChange={e => setData( Object.assign( {}, data, {telephone : e.target.value}))} />
                        <br/>
                        <br/>
                    </p>
                    <p>
                        {t("MOBILE")}: <input class="profilfeld" type="text" value={data.mobile} onChange={e => setData( Object.assign( {}, data, {mobile : e.target.value}))} />
                        <br/>
                        <br/>
                    </p>
                    <br/>
                    <p>
                        <label>{t("SEND_MESSAGES")}:&nbsp;
                            <select name="shownotifications" id="shownotifications" size="1" value={data.shownotifications} onChange={e => setData( Object.assign( {}, data, {shownotifications : e.target.value}))}>
                                <option value="N">{t("MSG_DISABLE")}</option>
                                <option value="H">{t("MSG_HOURLY")}</option>
                                <option value="D">{t("MSG_DAILY")}</option>
                                <option value="W">{t("MSG_WEEKLY")}</option>
                            </select>
                        </label>
                    </p>
                    <br/>
                    <p>
                        <input type="checkbox" id="showintro" name="showintro" checked={data.showintro} onChange={e => setData( Object.assign( {}, data, {showintro : e.target.value}))} />
                        <label htmlFor="showintro">{t("SHOW_INTRO")}</label>
                    </p>
                    <p>
                        <input type="checkbox" id="showhelp" name="showhelp" checked={data.showhelp} onChange={e => setData( Object.assign( {}, data, {showhelp : e.target.value}))} />
                        <label htmlFor="showhelp">{t("SHOW_HELPBUTTONS")}</label>
                    </p>
                    <p>
                        <input type="checkbox" id="showclearname" name="showclearname" checked={data.showclearname} onChange={e => setData( Object.assign( {}, data, {showclearname : e.target.value}))} />
                        <label htmlFor="showclearname">{t("SHOW_CLEARNAME")}</label>
                    </p>
                    <p>
                        <input type="checkbox" id="showcontact" name="showcontact" checked={data.showcontact} onChange={e => setData( Object.assign( {}, data, {showcontact : e.target.value}))} />
                        <label htmlFor="showcontact">{t("SHOW_CONTACTS")}</label>
                    </p>
                    <br/>
                    <label>{t("LANGUAGE")}:&nbsp;
                        <select name="language" id="language" size="1" value={data.language} onChange={e => changeLanguage(e.target.value)}>
                            <option value="de_de">Deutsch</option>
                            <option value="en_gb">English</option>
                            <option value="es_es">Español</option>
                            <option value="fr_fr">Français</option>
                        </select>
                    </label>
                    <br/>
                    <br/>
                    <p>
                        <input type="button" onClick={ () => doUpdateUser() } value={t("SAVE_PROFILE")}/>
                    </p>
                </form>
                <br/>
                <div className="zweitesdiv">
                    Chat zur Unterstützung anfordern
                </div>
                <br/>
                <div className="zweitesdiv">
                    Video zur Unterstützung anfordern
                </div>
            </div>
        </div>
    );
}

export default withTranslation(['profilePage', 'common'] )(ProfilePage);
