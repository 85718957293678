/******************************************************************************
 *                                                                            *
 *  exceptiond.js                                                             *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 09.03.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React from "react";

var collaborationExceptions = new Map();

collaborationExceptions.set( "1", "USERNAME_ALREADY_EXISTS" );
collaborationExceptions.set( "2", "EMAILADDRESS_ALREADY_USED" );
collaborationExceptions.set( "3", "INVALID_ACTIVATIONKEY" );
collaborationExceptions.set( "4", "ACCOUT_NOT_ENABLED" );
collaborationExceptions.set( "5", "UNKNOWN_USERNAME" );
collaborationExceptions.set( "6", "WRONG_PASSWORD" );
collaborationExceptions.set( "7", "ACCOUNT_HAS_BEEN_LOCKED" );
collaborationExceptions.set( "8", "ACCOUNT_IS_LOCKED" );
collaborationExceptions.set( "9", "UNKNOWN_EMAILADDRESS" );
collaborationExceptions.set( "10", "PW_NOT_CHANGEABLE" );

export default collaborationExceptions
