export const apiMenuItems = [
    {
      "title": "",
      "url": "showHomePage",
      "cName": "nav-item",
      "image": "fas fa-home",
      "submenu": []
    },
    {
      "title": "Anmelden",
      "url": "showLoginPage",
      "cName": "nav-item",
      "image": "",
      "submenu": []
    },
    {
      "title": "Mitmachen",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Registrieren",
          "url": "showRegisterPage",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Passwort zurücksetzen",
          "url": "showPasswordResetPage",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Nettiquette",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Verifizierung - Leitfaden",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Verifizierung - DSGVO-Verpflichtungserklärung",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Verifizierung - DSGVO-Erklärung",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Gruppen",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Deutschland",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Baden-Württemberg",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Bayern",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Berlin",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Brandenburg",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Bremen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Hamburg",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Hessen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Mecklenburg-Vorpommern",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Niedersachsen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Nordrhein-Westpfahlen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Rheinland-Pfalz",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Saarland",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Sachsen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Sachsen-Anhalt",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Schleswig-Holstein",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Thüringen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Aktionen",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Veranstaltungen und Termine",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Themen und Arbeitsgruppen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Kampagnen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Abstimmungen - Orts-/Regionalgruppenebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Abstimmungen - Landesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Abstimmungen - Bundesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wahlen - Orts-/Regionalgruppenebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wahlen - Landesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wahlen - Bundesebene",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wissen & Fakten",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Über uns",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Organisationen",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wozu diese Seite",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Seitenbetreiber",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Wer wir sind",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Impressum",
          "url": "showImprint",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    },
    {
      "title": "Kontakt / Hilfe",
      "url": "",
      "cName": "nav-item",
      "image": "",
      "submenu": [
        {
          "title": "Video Tutorials",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "FAQ",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Kontaktformular",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Newsletter",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Techblog",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Intro",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        },
        {
          "title": "Einführung",
          "url": "",
          "cName": "nav-submenu-item",
          "image": ""
        }
      ]
    }
  ];
export default {
    apiMenuItems    
}