/******************************************************************************
 *                                                                            *
 *  HomePage.js                                                               *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React from "react";
import './HomePage.css'
import { withTranslation } from 'react-i18next';

const HomePage = (props) => {
    const { t } = props;

    return (
        <div id="divLoginOuter" className="stddlg-outer">
            <div id="divLogin" className="stddlg">
                <div id="divHomepage" className="homepage">
                    {t("WELCOME")}
                </div>
            </div>
        </div>
    );
}

export default withTranslation(['homePage', 'common'] )(HomePage);
