import React, { useState, useEffect } from 'react';
import './FontSizeChanger.css'

const FontSizeChanger = () => {
  // Die Schriftgröße aus dem localStorage laden oder eine Standardgröße verwenden
  const [fontSize, setFontSize] = useState(
    localStorage.getItem('fontSize') || '16px'
  );

  const changeFontSize = (size) => {
    setFontSize(size);
    localStorage.setItem('fontSize', size);  // Speichert die neue Größe im localStorage
  };

  useEffect(() => {
    document.documentElement.style.fontSize = fontSize;
  }, [fontSize]);

  return (
    <div>
        Schriftgröße: &nbsp;
      <button className="ra_button" onClick={() => changeFontSize('16px')}>+0</button>
      <button className="ra_button" onClick={() => changeFontSize('17px')}>+1</button>
      <button className="ra_button" onClick={() => changeFontSize('18px')}>+2</button>
      <button className="ra_button" onClick={() => changeFontSize('19px')}>+3</button>
      <button className="ra_button" onClick={() => changeFontSize('20px')}>+4</button>
    </div>
  );
};

export default FontSizeChanger;