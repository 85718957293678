/******************************************************************************
 *                                                                            *
 *  ImprintPage.js                                                            *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React from "react";
import './ImprintPage.css'
import { withTranslation } from 'react-i18next';

const ImprintPage = (props) => {
    const { t } = props;

    return (
        <div className="stddlg-outer">
            <div className="stddlg">
                <h2>{t("IMPRINT")}</h2>
                <br/>
                Claus Hansen & Martin Wolf<br/>
                Heddingheimer Str. 25<br/>
                65795 Hattersheim<br/>
                <br/>
                Tel 06190 9191299<br/>
                eMail info@wolf-itc.de<br/>
            </div>
        </div>
    );
}

export default withTranslation(['imprintPage', 'common'])(ImprintPage);
