/******************************************************************************
 *                                                                            *
 *  RegisterPage.js                                                           *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React from "react";

import LoginPage from "./LoginPage";
import './RegisterPage.css';
import Context from "../context";
import { withTranslation } from 'react-i18next';

const RegisterPage = (props) => {
    const { t, showLogin } = props;

    const doRegister = (event) => {
        const errorMessageElementFirstName = document.getElementById('error-message-firstname');
        const errorMessageElementLastName = document.getElementById('error-message-lastname');
        const errorMessageElementCity = document.getElementById('error-message-city');
        const errorMessageElementZipcode = document.getElementById('error-message-zipcode');
        const errorMessageElementUser = document.getElementById('error-message-user');
        const errorMessageElementEmail = document.getElementById('error-message-mail');
        const errorMessageElementPasswordValid = document.getElementById('error-message-pwvalid');
        const errorMessageElementPasswordRepeat = document.getElementById('error-message-pwrepeat');
        

        // Clear previous error message
        errorMessageElementFirstName.textContent = '';
        errorMessageElementFirstName.style.display = 'none';

        // Clear previous error message
        errorMessageElementLastName.textContent = '';
        errorMessageElementLastName.style.display = 'none';

        // Clear previous error message
        errorMessageElementCity.textContent = '';
        errorMessageElementCity.style.display = 'none';
 
        // Clear previous error message
        errorMessageElementZipcode.textContent = '';
        errorMessageElementZipcode.style.display = 'none';

        // Clear previous error message
        errorMessageElementUser.textContent = '';
        errorMessageElementUser.style.display = 'none';

        // Clear previous error message
        errorMessageElementEmail.textContent = '';
        errorMessageElementEmail.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordValid.textContent = '';
        errorMessageElementPasswordValid.style.display = 'none';

        // Clear previous error message
        errorMessageElementPasswordRepeat.textContent = '';
        errorMessageElementPasswordRepeat.style.display = 'none';

        
        

        const firstname = document.getElementById('firstname').value;
        if ( firstname == "" ) {
            errorMessageElementFirstName.textContent = t("ENTER_FIRSTNAME"); // Assuming t is a translation function
            errorMessageElementFirstName.style.display = 'block'; // Show the error message
            return;
        }

        const lastname = document.getElementById('lastname').value;
        if ( lastname == "" ) {
            errorMessageElementLastName.textContent = t("ENTER_LASTNAME"); // Assuming t is a translation function
            errorMessageElementLastName.style.display = 'block'; // Show the error message
            return;
        }

        const city = document.getElementById('city').value;
        if ( city == "" ) {
            errorMessageElementCity.textContent = t("ENTER_CITY"); // Assuming t is a translation function
            errorMessageElementCity.style.display = 'block'; // Show the error message
            return;
        }
        
        const zipcode = document.getElementById('zipcode').value;
        if ( zipcode == "" ) {
            errorMessageElementZipcode.textContent = t("ENTER_ZIPCODE"); // Assuming t is a translation function
            errorMessageElementZipcode.style.display = 'block'; // Show the error message
            return;
        }
        
        const username = document.getElementById('username').value;
        if ( username == "" ) {
            errorMessageElementUser.textContent = t("ENTER_USERNAME"); // Assuming t is a translation function
            errorMessageElementUser.style.display = 'block'; // Show the error message
            return;
        }

        const email = document.getElementById('email').value;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            errorMessageElementEmail.textContent = t("INVALID_MAILFORMAT"); // Assuming t is a translation function
            errorMessageElementEmail.style.display = 'block'; // Show the error message
            return;
        }

        const password = document.getElementById('password').value;
        var pwOk = true;
        if ( password.match( /[^a-zA-Z0-9#*+-~@$%&!]/ )) pwOk = false;
        if ( !password.match( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#|*|+|-|~|@|$|%|&])[0-9a-zA-Z#*+-~@$%&!]{8,}$/ )) pwOk = false;
        if ( !pwOk ) {
            errorMessageElementPasswordValid.textContent =  t("ENTER_VALID_PASSWORD"); // Assuming t is a translation function
            errorMessageElementPasswordValid.style.display = 'block'; // Show the error message
            return;
        }

        const passwordRpt = document.getElementById('passwordRpt').value;
        if ( password != passwordRpt ) {
            errorMessageElementPasswordRepeat.textContent = t("PASSWORDS_DONT_MATCH"); // Assuming t is a translation function
            errorMessageElementPasswordRepeat.style.display = 'block'; // Show the error message
            return;
        }

        const activationKey = Context.generateToken( 16);

        var o = {
            firstname    : firstname,
            lastname     : lastname,
            zipcode      : zipcode,
            city         : city, 
            username     : username,
            email        : email,
            password     : password,
            mailSubject  : t("ACCOUNT_ACTIVATION"),
            activationkey: activationKey,
            language     : "de_de",
            mailBody     : t("MAIL_BODY_REGISTER", { "LINK" : Context.frontendURL+ "activateAccount?activationKey="+ activationKey })
        };
        Context.api.post('createUser', o ).then( res => {
            alert( t("ACCOUNT_WAS_CREATED") );
            props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

return (
    <div id="divRegisterOuter" className="stddlg-outer">
        {/* <div id="divRegister" className="stddlg"> */}
                
                <form>
                    <div className="flex-container">
                        <div className="row">
                        <h1 style={{ marginLeft: '30px' }}>{t("REGISTRATION")}</h1>
                        </div>
                        <div className="row">
                        <div className="column">
                            {/* <p>
                            <input className="loginfeld" placeholder={t("CITY")} type="text" name="city" id="city" required />
                            </p>
                            <div>
                            <span id="error-message-city" className="error" style={{display:'none', color:'red'}}></span>
                            </div>
                            <p>
                            <input className="loginfeld" placeholder={t("FIRSTNAME")} type="text" name="firstname" id="firstname" required />
                            </p>
                            <div>
                            <span id="error-message-firstname" className="error" style={{display:'none', color:'red'}}></span>
                            </div>
                            <p>
                            <input className="loginfeld" placeholder={t("LASTNAME")} type="text" name="lastname" id="lastname" required />
                            </p>
                            <div>
                            <span id="error-message-lastname" className="error" style={{display:'none', color:'red'}}></span>
                            </div> 
                            <p>
                            <input className="loginfeld" placeholder={t("ZIPCODE")} type="text" name="zipcode" id="zipcode" required />
                            </p>
                            <div>
                            <span id="error-message-zipcode" className="error" style={{display:'none', color:'red'}}></span>
                            </div>*/}
                            
                            <p>
                            <input className="loginfeld" placeholder={t("USERNAME")} type="text" name="username" id="username" required />
                            </p>
                            <div>
                            <span id="error-message-user" className="error" style={{display:'none', color:'red'}}></span>
                            </div>
                            <p> 
                                <input className="loginfeld" placeholder={t("MAIL")} type="email" name="email" id="email" required /> {/* Changed to email type */}
                            </p>
                            <div>
                            <span id="error-message-mail" className="error" style={{display:'none', color:'red'}}></span>
                            </div>
                            <p>
                                <input className="loginfeld" placeholder={t("PASSWORD")} type="password" name="password" id="password" required />
                            </p>
                            <div>
                            <span id="error-message-pwvalid" className="error" style={{display:'none', color:'red'}}></span>
                            </div>
                            <p>
                                <input className="loginfeld" placeholder={t("PASSWORD_RPT")} type="password" name="passwordRpt" id="passwordRpt" required />
                            </p>
                            <div>
                            <span id="error-message-pwrepeat" className="error" style={{display:'none', color:'red'}}></span>
                            </div>
                            <br />
                            <p>
                                <input className="login-button" type="button" onClick={() => doRegister()} value={t("CREATE_ACCOUNT")} />
                            </p>
                            <br />
                            <a href="" className="clickable-link" onClick={(e)=>{e.preventDefault();showLogin()}} >Sie haben bereits einen Account? Loggen Sie sich hier ein.</a> 
                        </div>
                        
                        <div className="column">
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: t('PASSWORD_RULES') }} />
                                <br /><br />
                            </p>
                        </div>
                        </div>
                    </div>
                </form>
            </div>
        /* </div> */
);
};

export default withTranslation(['registerPage', 'common'] )(RegisterPage);
