// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/******************************************************************************
 *                                                                            *
 *  ProfilePage.css                                                           *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 08.03.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
.profilfeld
{
    position : absolute;
    left     : 200px;
}

.zweitesdiv {
    height: 100px;
    width: 200px;
    background: white;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    border: 1px solid gray;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProfilePage.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;8EAY8E;AAC9E;;IAEI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":["/******************************************************************************\n *                                                                            *\n *  ProfilePage.css                                                           *\n *                                                                            *\n *  Version  : 1                                                              *\n *                                                                            *\n *  Author   : Martin Wolf (mw)                                               *\n *                                                                            *\n *  Date     : 08.03.2021                                                     *\n *                                                                            *\n *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *\n *                                                                            *\n * ***************************************************************************/\n.profilfeld\n{\n    position : absolute;\n    left     : 200px;\n}\n\n.zweitesdiv {\n    height: 100px;\n    width: 200px;\n    background: white;\n    text-align: center;\n    display: table-cell;\n    vertical-align: middle;\n    border: 1px solid gray;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
