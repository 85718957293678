// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/******************************************************************************
 *                                                                            *
 *  HomePage.css                                                              *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 08.03.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
.homepage {

}

.imgTitle {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;8EAY8E;AAC9E;;AAEA;;AAEA;IACI,WAAW;AACf","sourcesContent":["/******************************************************************************\n *                                                                            *\n *  HomePage.css                                                              *\n *                                                                            *\n *  Version  : 1                                                              *\n *                                                                            *\n *  Author   : Martin Wolf (mw)                                               *\n *                                                                            *\n *  Date     : 08.03.2021                                                     *\n *                                                                            *\n *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *\n *                                                                            *\n * ***************************************************************************/\n.homepage {\n\n}\n\n.imgTitle {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
