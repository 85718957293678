/******************************************************************************
 *                                                                            *
 *  LoginPage.js                                                              *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React from "react";
import './LoginPage.css'
import Context from "../context";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

const LoginPage = (props) => {
    const { t, showRegister } = props;

    const doLogin = () => {
        const user = document.getElementById('username').value;
        const errorMessageElementUser = document.getElementById('error-message-user');
        const errorMessageElementPassword = document.getElementById('error-message-pw');
        
        // Clear previous error message
        errorMessageElementUser.textContent = '';
        errorMessageElementUser.style.display = 'none';

        // Clear previous error message
        errorMessageElementPassword.textContent = '';
        errorMessageElementPassword.style.display = 'none';

        if (user === "") {
            errorMessageElementUser.textContent = t("ENTER_USERNAME"); // Assuming t is a translation function
            errorMessageElementUser.style.display = 'block'; // Show the error message
            return;
        } 

        const pw = document.getElementById('password').value;
        if ( pw === "" ) {
            errorMessageElementPassword.textContent = t("ENTER_PASSWORD"); // Assuming t is a translation function
            errorMessageElementPassword.style.display = 'block'; // Show the error message
            return;
        }
        
        

        const o = {
            username: user,
            password: pw
        }
        Context.api.post('doLogin', o ).then( res => {
            Context.username = user;
            Context.password = pw;
            Context.language = res.data;
            const encr = Context.encrypt( pw );
            Cookies.set( 'user', user );
            Cookies.set( 'pw', encr );
            Cookies.set( 'language', res.data );
            props.setUser(user);
            props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div id="divLoginOuter" className="stddlg-outer">
            <div id="divLogin" className="stddlg">
                <div>
                    <form >
                        <h1>Login</h1>
                        <p>
                            <br/><input class="loginfeld" placeholder={t('USERNAME')} type="text" name="username" id="username" required/>
                        </p>
                        <div>
                            <span id="error-message-user" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <p>
                            <input class="loginfeld" placeholder={t('PASSWORD')} type="password" name="password" id="password" required/>
                        </p>
                        <div>
                            <span id="error-message-pw" className="error" style={{display:'none', color:'red'}}></span>
                        </div>
                        <br/>
                        <p>
                            <input className="login-button" type="button" onClick={ () => doLogin() } value={t('LOGIN')}/>
                        </p>
                        <br/>
                        <a href="" className="clickable-link" onClick={(e)=>{e.preventDefault();showRegister()}} >{t("SWITCH_REG")}</a>
                    </form>
                </div>
                <br/>
                <div class="supportcontainer">
                    <div class="login-button ">
                        {t("HELP_CHAT")}
                    </div>
                    <br/>
                    <div className="login-button ">
                        {t("HELP_VIDEO")}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation(['loginPage', 'common'])(LoginPage);
