/******************************************************************************
 *                                                                            *
 *  App.js                                                                    *
 *                                                                            *
 *  Version  : 1                                                              *
 *                                                                            *
 *  Author   : Martin Wolf (mw)                                               *
 *                                                                            *
 *  Date     : 30.05.2021                                                     *
 *                                                                            *
 *  (C) 2021 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)       *
 *                                                                            *
 * ***************************************************************************/
import React from "react";
import Navbar from "./Navbar";
import { BrowserRouter as Routes, Router, Route, Link } from "react-router-dom";

import './App.css';
import Cookies from 'js-cookie'
import Context from "../context";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import axios from "axios";
import i18n from '../i18n';
import {withTranslation} from "react-i18next";

const App = (props) => {
    const { t, showLogin, showRegister} = props;

    

    // Set hostnames for front- and backend
    if ( Context.backendURL === "" ) {
        var hostname = window.location.hostname;
        //alert(hostname);
        Context.backendURL = 'https://'+ 'pff.ddnss.de'+ ':8081/';
        Context.frontendURL = 'http://pff.ddnss.de:3000/';
        Context.api = axios.create({
            baseURL: Context.backendURL
        })
    };

    // Fetch user from cookie if present
    var user = Cookies.get( 'user' );
    if ( user && ( user !== "" )) {
        if ( Context.username !== user) {
            Context.username = user;
            Context.password = Context.decrypt( Cookies.get( 'pw' ));
            Context.language = Cookies.get( 'language' );
            i18n.changeLanguage( Context.language );
        }
    } else {
        var lng = navigator.language.toLowerCase().replaceAll( "-", "_");
        switch ( lng ) {
            case "de" : lng = "de_de"; break;
            case "en" : lng = "en_gb"; break;
            case "en_us" : lng = "en_gb"; break;
            case "es" : lng = "es_es"; break;
            case "fr" : lng = "fr_fr"; break;
            default:
        }
        if ( Context.language !== lng) {
            Context.language = lng;
            i18n.changeLanguage( Context.language );
        }
    }

    // If this is a call for an account-activation, then retrieve key and activate the account
    if ( "/activateAccount" === window.location.pathname ) {
        var activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('activateAccount', o ).then( () => {
            alert( i18n.t("common:ACCOUNT_WAS_ACTIVATED") );
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    // If this is a call for a password-reset, then retrieve key and show reset-message
    if ( "/resetPassword" === window.location.pathname ) {
        let activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('resetPassword', o ).then( res => {
            alert( i18n.t("common:PASSWORD_WAS_RESET_TO")+ ":\n\n"+ res.data+ "\n\n"+ i18n.t("common:START_MESSAGE") );
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    // If this is a call for an account-deletion, then retrieve key and show deletion-message
    if ( "/deleteAccount" === window.location.pathname ) {
        let activationKey = Context.getQueryParams( window.location.href.split('?')[1] ).get( "activationKey" );
        const o = {
            activationKey: activationKey
        }
        Context.api.post('deleteAccount', o ).then( () => {
            Cookies.set( 'user', '' );
            Cookies.set( 'pw', '' );
            Context.username = '';
            Context.password = '';
            alert( i18n.t("common:ACCOUNT_HAS_BEEN_DELETED"));
            window.location = Context.frontendURL;
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div className="App" style={{height:'100vh'}}>
            <div className="header-container">
            <img className="company-logo" src="/hilfmir.logo.jpg" alt="Company Logo"/>
            {/*<img className="imgTitle" alt={t("IMG_TITLE")} src={process.env.PUBLIC_URL + '/headerlogo.png'}/>*/}
            <div id="divNavbar">
                <Navbar />
                
            </div>  
            </div>
            <div id="pageArea">
                <HomePage />
            </div> 
        </div>
       
    );
}

export default withTranslation('common')(App);
